"use client";

import React, { useState, useRef, useEffect } from 'react';
import TestimonialCard from './TestimonialCard';
import TestimonialColumn from './TestimonialColumn';
import { testimonialData, columnData } from '../data/testimonials';

const TestimonialWall = () => {
  // Filter testimonials to only show the specified IDs
  const filteredTestimonials = testimonialData.filter(testimonial => 
    ['1', '2', '3', '4', '5', '6', '7', '11', '14'].includes(testimonial.id)
  );
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  
  // Handle swipe gestures
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };
  
  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };
  
  const handleTouchEnd = () => {
    const swipeDistance = touchEndX.current - touchStartX.current;
    const minSwipeDistance = 50;
    
    if (swipeDistance > minSwipeDistance) {
      // Swipe right - go to previous
      setCurrentIndex(prev => (prev > 0 ? prev - 1 : filteredTestimonials.length - 1));
    } else if (swipeDistance < -minSwipeDistance) {
      // Swipe left - go to next
      setCurrentIndex(prev => (prev < filteredTestimonials.length - 1 ? prev + 1 : 0));
    }
  };

  // Desktop version - show columns
  const renderDesktopView = () => {
    // Using pre-defined column data from the testimonials file
    // This ensures all testimonials are properly displayed

    return (
      <div className="relative flex justify-between gap-4 h-[800px] overflow-hidden">
        {/* Radial fade overlay - creates subtle vignette effect */}
        <div className="absolute inset-0 pointer-events-none z-10" 
          style={{
            background: 'radial-gradient(ellipse at center, transparent 40%, rgba(11,11,12,0.4) 70%, rgba(11,11,12,0.8) 90%)',
            mixBlendMode: 'multiply',
          }}
        />
        
        {/* Horizontal fade overlays for more natural blending at the sides */}
        <div className="absolute top-0 bottom-0 left-0 w-[10%] pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to right, rgba(11,11,12,0.9), transparent)',
          }}
        />
        <div className="absolute top-0 bottom-0 right-0 w-[10%] pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to left, rgba(11,11,12,0.9), transparent)',
          }}
        />
        
        {/* Vertical fade overlays for top and bottom */}
        <div className="absolute top-0 left-0 right-0 h-[15%] pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to bottom, rgba(11,11,12,0.95), transparent)',
          }}
        />
        <div className="absolute bottom-0 left-0 right-0 h-[15%] pointer-events-none z-10"
          style={{
            background: 'linear-gradient(to top, rgba(11,11,12,0.95), transparent)',
          }}
        />

        {/* Left Column */}
        <TestimonialColumn
          key="left-column"
          testimonials={columnData.leftColumn}
          direction="down"
          speed={0.7}
          initialOffset={30}
        />

        {/* Middle Column */}
        <TestimonialColumn
          key="middle-column"
          testimonials={columnData.middleColumn}
          direction="up"
          speed={0.8}
          initialOffset={15}
        />

        {/* Right Column - now moving down */}
        <TestimonialColumn
          key="right-column"
          testimonials={columnData.rightColumn}
          direction="down"
          speed={1.0}
          initialOffset={70}
        />
      </div>
    );
  };

  // Mobile version - show swipeable cards
  const renderMobileView = () => {
    return (
      <div 
        className="relative w-full"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Card container with fixed square size */}
        <div className="relative w-full aspect-square mx-auto max-w-[350px]">
          {filteredTestimonials.map((testimonial, index) => (
            <div 
              key={testimonial.id}
              className={`absolute inset-0 transition-all duration-300 ${
                index === currentIndex 
                  ? 'opacity-100 transform-none z-10' 
                  : 'opacity-0 scale-95 z-0'
              }`}
            >
              <TestimonialCard testimonial={testimonial} />
            </div>
          ))}
        </div>
        
        {/* Pagination indicators */}
        <div className="flex justify-center mt-6 gap-2">
          {filteredTestimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                index === currentIndex 
                  ? 'bg-white w-4' 
                  : 'bg-gray-600'
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className="relative overflow-hidden bg-transparent py-20">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Heading */}
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Loved by creators everywhere
          </h2>
          <p className="text-lg text-gray-400">
            Here's what people say about us
          </p>
        </div>

        {/* Responsive layout - desktop columns vs mobile swipeable cards */}
        <div className="hidden md:block">
          {renderDesktopView()}
        </div>
        <div className="block md:hidden">
          {renderMobileView()}
        </div>
      </div>
    </section>
  );
};

export default TestimonialWall; 