"use client";

import React from 'react';
import { Dice1, Dice2, Dice3, Dice4, Dice5, Dice6 } from 'lucide-react';
import { Testimonial } from '../lib/api/types';

interface TestimonialCardProps {
  testimonial: Testimonial;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ testimonial }) => {
  // Determine which dice to show based on the testimonial ID
  const getDiceIcon = () => {
    // Convert ID to number and get remainder when divided by 6
    // This ensures we cycle through dice 1-6 regardless of how many testimonials
    const diceNumber = (parseInt(testimonial.id) % 6) || 6; // If remainder is 0, use dice-6
    
    switch (diceNumber) {
      case 1: return <Dice1 size={32} className="text-white" />;
      case 2: return <Dice2 size={32} className="text-white" />;
      case 3: return <Dice3 size={32} className="text-white" />;
      case 4: return <Dice4 size={32} className="text-white" />;
      case 5: return <Dice5 size={32} className="text-white" />;
      case 6: return <Dice6 size={32} className="text-white" />;
      default: return <Dice1 size={32} className="text-white" />;
    }
  };

  return (
    <div className="relative p-6 mb-4 rounded-xl bg-[#0e0e0e] border border-[#222222] transition-all duration-300 group overflow-hidden">
      {/* Blue ellipse - left */}
      <div 
        className="absolute bottom-0 left-[-5%] w-[70%] h-[85%] rounded-full opacity-0 group-hover:opacity-60 transition-opacity duration-500 ease-in-out group-hover:delay-[50ms] delay-0"
        style={{
          background: '#637AFF',
          filter: 'blur(30px)',
          zIndex: 0,
          transform: 'translateY(30%) translateX(-30%)',
        }}
      />
      
      {/* Pink ellipse - middle */}
      <div 
        className="absolute bottom-0 left-[25%] w-[65%] h-[70%] rounded-full opacity-0 group-hover:opacity-60 transition-opacity duration-500 ease-in-out group-hover:delay-[100ms] delay-0"
        style={{
          background: '#FF578A',
          filter: 'blur(30px)',
          zIndex: 0,
          transform: 'translateY(60%)',
        }}
      />
      
      {/* Orange ellipse - right */}
      <div 
        className="absolute bottom-0 right-[-10%] w-[75%] h-[80%] rounded-full opacity-0 group-hover:opacity-60 transition-opacity duration-500 ease-in-out group-hover:delay-[150ms] delay-0"
        style={{
          background: '#FFBF00',
          filter: 'blur(30px)',
          zIndex: 0,
          transform: 'translateY(45%) translateX(40%)',
        }}
      />

      {/* Subtle border glow */}
      <div
        className="absolute inset-0 opacity-0 group-hover:opacity-40 transition-opacity duration-500 ease-in-out group-hover:delay-[80ms] delay-0 rounded-xl"
        style={{
          boxShadow: '0 0 15px 1px rgba(117, 165, 255, 0.15), 0 0 15px 1px rgba(255, 132, 177, 0.15), 0 0 15px 1px rgba(255, 206, 128, 0.15)',
          zIndex: 0,
        }}
      />

      {/* Content */}
      <div className="relative z-10">
        <p className="text-gray-300 leading-relaxed mb-6">{testimonial.quote}</p>

        <div className="flex items-center gap-4 mt-auto">
          <div className="flex items-center justify-center w-9 h-9 rounded-md bg-[#1a1a1a] flex-shrink-0 border border-[#333333]">
            {getDiceIcon()}
          </div>
          <div>
            <h3 className="text-white font-semibold text-lg">{testimonial.name}</h3>
            <p className="text-gray-400 text-sm">{testimonial.company}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;