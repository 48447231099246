"use client";

import React, { useRef, useState, useEffect } from 'react';
import TestimonialCard from './TestimonialCard';
import { Testimonial } from '../lib/api/types';

interface TestimonialColumnProps {
  testimonials: Testimonial[];
  direction: 'up' | 'down';
  speed: number;
  initialOffset?: number;
}

const TestimonialColumn: React.FC<TestimonialColumnProps> = ({
  testimonials,
  direction,
  speed,
  initialOffset = 0,
}) => {
  const columnRef = useRef<HTMLDivElement>(null);
  const firstColumnRef = useRef<HTMLDivElement>(null);
  const secondColumnRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [columnHeight, setColumnHeight] = useState(0);
  const [animationReady, setAnimationReady] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const animationRef = useRef<number | null>(null);
  const scrollPositionRef = useRef(0);
  const initialPositionSet = useRef(false);

  // Create a single set of testimonials that will be duplicated in the DOM
  // This ensures we have consistent content for animation
  const testimonialSet = [...testimonials];
  
  useEffect(() => {
    // Function to calculate and set column height
    const calculateHeight = () => {
      if (columnRef.current) {
        // Get the exact height of the content
        const exactHeight = columnRef.current.scrollHeight;
        setColumnHeight(exactHeight);
        
        // Set initial position based on initialOffset if not already set
        if (!initialPositionSet.current && initialOffset > 0) {
          const initialPos = (initialOffset / 100) * exactHeight;
          scrollPositionRef.current = direction === 'up' ? -initialPos : initialPos;
          
          // Apply initial position directly to DOM elements
          if (firstColumnRef.current && secondColumnRef.current) {
            firstColumnRef.current.style.transform = `translateY(${scrollPositionRef.current}px)`;
            secondColumnRef.current.style.transform = `translateY(${scrollPositionRef.current}px)`;
          }
          
          initialPositionSet.current = true;
        }
        
        // Set animation ready and make visible with minimized delay
        requestAnimationFrame(() => {
          setAnimationReady(true);
          requestAnimationFrame(() => {
            setIsVisible(true);
          });
        });
      }
    };

    // Calculate initially
    calculateHeight();
    
    // Recalculate on window resize
    window.addEventListener('resize', calculateHeight);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, [testimonials, direction, initialOffset]);

  // Implement smooth scrolling animation with requestAnimationFrame
  useEffect(() => {
    if (!animationReady || !columnHeight) return;
    
    let startTime: number | null = null;
    const totalDistance = columnHeight;
    // Speed is now pixels per second - adjust based on your needs
    const pixelsPerSecond = speed * 40;
    let lastTimestamp = 0;
    
    const step = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      
      if (isHovered) {
        // If hovered, just request the next frame without updating position
        animationRef.current = requestAnimationFrame(step);
        return;
      }
      
      // Calculate time elapsed since last frame
      const deltaTime = lastTimestamp ? timestamp - lastTimestamp : 0;
      lastTimestamp = timestamp;
      
      // Only update if we have a valid deltaTime (prevents jumps after tab switch)
      if (deltaTime > 0 && deltaTime < 100) {
        // Calculate how many pixels to move based on elapsed time
        const pixelsToMove = (deltaTime / 1000) * pixelsPerSecond;
        
        // Calculate new position
        if (direction === 'up') {
          scrollPositionRef.current -= pixelsToMove;
        } else {
          scrollPositionRef.current += pixelsToMove;
        }
        
        // Reset position when we've moved a full column height to create seamless loop
        if (direction === 'up' && Math.abs(scrollPositionRef.current) >= totalDistance) {
          scrollPositionRef.current = scrollPositionRef.current % totalDistance; // Use modulo for smooth looping
          startTime = timestamp; // Reset start time
        } else if (direction === 'down' && scrollPositionRef.current >= totalDistance) {
          scrollPositionRef.current = scrollPositionRef.current % totalDistance; // Use modulo for smooth looping
          startTime = timestamp; // Reset start time
        }
        
        // Apply transform directly to DOM elements for better performance
        if (firstColumnRef.current && secondColumnRef.current) {
          firstColumnRef.current.style.transform = `translateY(${scrollPositionRef.current}px)`;
          secondColumnRef.current.style.transform = `translateY(${scrollPositionRef.current}px)`;
        }
      }
      
      animationRef.current = requestAnimationFrame(step);
    };
    
    // Start the animation
    animationRef.current = requestAnimationFrame(step);
    
    // Cleanup
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animationReady, columnHeight, direction, isHovered, speed]);

  return (
    <div
      className="relative flex-1 overflow-hidden h-full"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ minHeight: '600px' }}
    >
      {/* Main column with seamless looping */}
      <div 
        className={`relative w-full h-full transition-opacity duration-500 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      >
        {/* Container for the infinite scroll effect */}
        <div className="absolute w-full h-full">
          {/* First copy */}
          <div
            ref={firstColumnRef}
            className="absolute w-full"
            style={{
              top: 0,
              willChange: 'transform',
            }}
          >
            {testimonialSet.map((testimonial, index) => (
              <TestimonialCard
                key={`${testimonial.id}-${index}-main`}
                testimonial={testimonial}
              />
            ))}
          </div>
          
          {/* Second copy - positioned to create a seamless loop */}
          <div
            ref={secondColumnRef}
            className="absolute w-full"
            style={{
              top: direction === 'up' ? columnHeight : -columnHeight,
              willChange: 'transform',
            }}
          >
            {testimonialSet.map((testimonial, index) => (
              <TestimonialCard
                key={`${testimonial.id}-${index}-secondary`}
                testimonial={testimonial}
              />
            ))}
          </div>
          
          {/* Hidden reference for measuring height */}
          <div
            ref={columnRef}
            className="absolute w-full opacity-0 pointer-events-none"
            aria-hidden="true"
          >
            {testimonialSet.map((testimonial, index) => (
              <TestimonialCard
                key={`${testimonial.id}-${index}-measure`}
                testimonial={testimonial}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialColumn; 