import { Testimonial } from '../lib/api/types';

export const testimonialData: Testimonial[] = [
  {
    id: '1',
    name: 'ViktOnion',
    company: 'Twitch',
    quote: 'One of the AI clips got 400 views in 2 days and usually my videos in general get like 50 views at best so it definitely works',
    avatar: '/avatars/avatar1.jpg',
    gradient: 'linear-gradient(45deg, #FF6B6B 0%, #4ECDC4 100%)',
  },
  {
    id: '2',
    name: 'CaramelRibbon',
    company: 'Reddit',
    quote: "Saving for later when I start streaming! Thank you! I'm chronically ill so I felt clipping and editing would take into my resting time. This will help!!",
    avatar: '/avatars/avatar2.jpg',
    gradient: 'linear-gradient(45deg, #A8E6CF 0%, #3D84A8 100%)',
  },
  {
    id: '3',
    name: 'hacksarplays',
    company: 'Reddit',
    quote: "This is so amazing, you're wonderful. 😭",
    avatar: '/avatars/avatar3.jpg',
    gradient: 'linear-gradient(45deg, #FFD93D 0%, #FF6B6B 100%)',
  },
  {
    id: '4',
    name: 'shadowscorrupt',
    company: 'Reddit',
    quote: "i suck at making clips. But this made me feel pretty good about my own clips. thanks",
    avatar: '/avatars/avatar4.jpg',
    gradient: 'linear-gradient(45deg, #6C63FF 0%, #FF6584 100%)',
  },
  {
    id: '5',
    name: 'AnEternalEnigma',
    company: 'Twitch',
    quote: "I've used it and it is very good.",
    avatar: '/avatars/avatar5.jpg',
    gradient: 'linear-gradient(45deg, #4ECDC4 0%, #556270 100%)',
  },
  {
    id: '6',
    name: 'Aframovici',
    company: 'Reddit',
    quote: "Ive tried it with vods and i got 2-3 shorts from them that are good, which is 2-3 shorts more than others :)",
    avatar: '/avatars/avatar6.jpg',
    gradient: 'linear-gradient(45deg, #FF8C42 0%, #29648A 100%)',
  },
  {
    id: '7',
    name: 'juicenoose',
    company: 'Twitch',
    quote: "I was impressed [with] how well it worked for me as a vtuber[,] I was a bit worried it wouldn't pick up some stuff because of that",
    avatar: '/avatars/avatar7.jpg',
  },
  {
    id: '8',
    name: 'YoghurtThese9544',
    company: 'Reddit',
    quote: "As a user who has tried many automated solutions, I can vouche that this is the only one that has actually gotten me high quality clips",
    avatar: '/avatars/avatar8.jpg',
  },
  {
    id: '9',
    name: 'MarcusIsSmart',
    company: 'Discord',
    quote: "Been using this for a few weeks now and it's amazing at finding the best moments from my streams. Total game changer!",
    avatar: '/avatars/avatar9.jpg',
    gradient: 'linear-gradient(45deg, #845EC2 0%, #D65DB1 100%)',
  },
  {
    id: '10',
    name: 'Khaiden Tajigen',
    company: 'Reddit',
    quote: "I finally got a stream's highlights processed properly and they look really promising, I'm loving this already!",
    avatar: '/avatars/avatar10.jpg',
    gradient: 'linear-gradient(45deg, #FF9671 0%, #FFC75F 100%)',
  },
  {
    id: '11',
    name: 'Victor Suski',
    company: 'BoomTV',
    quote: "For every 1 clip on other platforms, I get 4 on Saved.",
    avatar: '/avatars/avatar11.jpg',
    gradient: 'linear-gradient(45deg, #00C9A7 0%, #4B4453 100%)',
  },
  {
    id: '12',
    name: 'sillysillybilly',
    company: 'Reddit',
    quote: "My clips are actually watchable now! This tool is incredible.",
    avatar: '/avatars/avatar12.jpg',
    gradient: 'linear-gradient(45deg, #F15BB5 0%, #9B5DE5 100%)',
  },
  {
    id: '13',
    name: 'MacBatz',
    company: 'Discord',
    quote: "Hell yeah I subbed!",
    avatar: '/avatars/avatar13.jpg',
    gradient: 'linear-gradient(45deg, #00BBF9 0%, #00F5D4 100%)',
  },
  {
    id: '14',
    name: 'Mr. SilentChaos',
    company: 'Twitch',
    quote: "This tool is the definitive way to quickly curate, prep, and do your short form content on YouTube and twitch. Let it do the work and you worry about being yourself on stream!",
    avatar: '/avatars/avatar14.jpg',
    gradient: 'linear-gradient(45deg, #FF99C8 0%, #FCF6BD 100%)',
  },
  {
    id: '15',
    name: 'nova',
    company: 'Discord',
    quote: "As a new streamer, this has been super helpful. Like having an editor by my side!",
    avatar: '/avatars/avatar15.jpg',
    gradient: 'linear-gradient(45deg, #A2D2FF 0%, #BDE0FE 100%)',
  },
  {
    id: '16',
    name: 'starlight_',
    company: 'Reddit',
    quote: "One of the most useful tools I've found- Makes highlight reels so easy to create.",
    avatar: '/avatars/avatar16.jpg',
    gradient: 'linear-gradient(45deg, #CDB4DB 0%, #FFC8DD 100%)',
  },
  {
    id: '17',
    name: 'Matt',
    company: 'Project Sparrow',
    quote: "It's been a real time investment streaming, getting clips, then editing them etc... so thank you so much for making a tool like this! It helps us market our game a lot easier!",
    avatar: '/avatars/avatar17.jpg',
    gradient: 'linear-gradient(45deg, #2EC4B6 0%, #FF9F1C 100%)',
  },

];

export const columnData = {
  leftColumn: testimonialData.filter(t => ['1', '4', '7', '10', '13', '16'].includes(t.id)),
  middleColumn: testimonialData.filter(t => ['2', '5', '8', '11', '14', '17'].includes(t.id)), 
  rightColumn: testimonialData.filter(t => ['3', '6', '9', '12', '15'].includes(t.id)),
};